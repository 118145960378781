.tools-footer {
  position: fixed;
  bottom: 0;
  background-color: #ff0000;
  color: #ffffff;
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: table;
  height: 50px;
  line-height: 50px;

  .department-item {
    display: table-cell;

    .department-bullet {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 25px auto;
      width: 25px;
      height: 40px;

      &.red {
        background-image: url('img/red.png');
      }

      &.green {
        background-image: url('img/green.png');
      }

      &.grey {
        background-image: url('img/grey.png');
      }

      &.blue {
        background-image: url('img/blue.png');
      }

      &.orange {
        background-image: url('img/orange.png');
      }

      &.yellow {
        background-image: url('img/yellow.png');
      }
    }
  }

  .department-bullet {
    margin: 0.2em auto 0.2em 1rem;
    vertical-align: middle;
  }

  .mobile-only {
    display: inline !important;
  }

  .web-only {
    display: none !important;
  }
}

.popup-map-department {
  .department-name {
    text-align: center;
    text-transform: uppercase;
  }
  .department-descr {
    text-align: center;
    text-transform: uppercase;
  }

  .img-container {
    padding: 2rem 0 2rem 0;
    text-align: center;

    img {
      max-width: 200px;
      max-height: auto;
    }
  }
}

@media (min-width: 768px) {

  .tools-footer {

    .department-item {

      .department-bullet {
        width: 30px;
        height: 35px;
        background-size: 30px auto;
      }
    }

    .web-only {
      display: inline !important;
    }

    .mobile-only {
      display: none !important;
    }
  }
}
