.checkbox-custom-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .control-group {
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    text-align: left;
    width: 100%;
  }

  .control {
    display: block;
    position: relative;
    padding-top: 5px;
    padding-left: 50px;
    cursor: pointer;
    font-style: italic;
    font-weight: normal;
  }

  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control__indicator {
    position: absolute;
    top: 50%;
    left: 0;
    height: 28px;
    width: 28px;
    margin-top: -10px;
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
  }

  .control input:checked ~ .control__indicator {
    background: #ffffff;
  }

  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  .control__indicator::after {
    content: '';
    position: absolute;
    display: none;
  }

  .control input:checked ~ .control__indicator::after {
    display: block;
  }

  .control--checkbox .control__indicator::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('img/check.png');
    background-position: center center;
    background-size: 20px 18px;
    background-repeat: no-repeat;
  }

  .control--checkbox input:disabled ~ .control__indicator::after {
    border-color: #7b7b7b;
  }
}
