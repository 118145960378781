.at-navbar {
  height: 8rem;
  background-color: white;
  background-image: url('./img/logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 4.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: black;
  position: relative;
}

.at-navbar ul { display: none; }

.burger-button {
  position: relative;
  padding: 1rem;
  margin-left: -1rem;
  background-color: transparent;
  background-image: none;
  border: 0;
  margin-top: 1.9rem;
}

.burger-button .icon-bar {
  display: block;
  width: 2.4rem;
  height: 0.4rem;
  border-radius: 1px;
  background-color: black;
}

.burger-button .icon-bar + .icon-bar {
  margin-top: 4px;
}

.burger-button.highlighted::after {
  content: ' ';
  position: absolute;
  background-color: red;
  right: 5px;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
}

.logout-btn {
  cursor: pointer;
}

@media (min-width: 768px) {

  .burger-button {
    display: none;
  }

  .at-navbar {
    background-position: 8rem center;
    padding-left: 8rem;
    padding-right: 8rem;
    font-size: 1.4rem;
  }

  .at-navbar ul.menu-horizontal {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 17rem;
    line-height: 4.6rem;
    padding-left: 150px;
  }

  .at-navbar ul.contact-info-horizontal {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 8rem;
    line-height: 4.6rem;
  }

  .at-navbar li {
    display: inline;
    text-transform: uppercase;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  .at-navbar .menu-horizontal li a {
    font-weight: bold;
  }

  .at-navbar .menu-horizontal li.active span {
    padding-bottom: 1.1rem;
    border-bottom: 0.5rem solid red;
  }
}
