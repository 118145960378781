.tool-details {

  .section-header {
    color: #000000;
  }

  textarea {
    width: 100%;
  }

  .title {
    margin-top: 20px;
    font-size: 2em;
  }

  .block-container {
    background-color: #ffffff;
  }

  .block-header {
    margin-top: 30px;
    color: #ff0000;
  }

  .web-only {
    display: none;
  }

  .mobile-only {
    display: inherit;
  }

  .department-bullet {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-radius: 1em;
  }

  .tool-item {
    padding: 1em 0;
    border-bottom: 1px solid #808080;
    line-height: 1em;
  }

  .highlighted {
    font-weight: bold;
  }

  .field:first-child {
    margin-top: 10px;
  }

  .field span {
    text-transform: uppercase;
  }

  .entry {
    font-weight: bold;
  }

  .row.tool-event-container {
    margin-left: -2rem;
    margin-right: -2rem;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1.5rem 0.5rem;
  }

  .policy-div {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 1rem;
    padding-top: 1rem;

    .checkbox-custom-container {
      padding-left: 0;
    }
  }

  .buttons {
    margin-top: 1.5rem;
  }

  .row.tool-event-container table {
    width: 100%;
    font-size: 1.1rem;
    border-bottom: 1px solid #dcdcdc;
    text-transform: uppercase;

    td {
      padding: 1.5rem 0.2rem;
      font-size: 1.1rem;
    }

    th {
      padding: 0 0.2rem 2rem 0.2rem;
      line-height: 1rem;
      color: #ff0000;
    }

    tbody td {
      font-weight: bold;

      .due-date {
        font-weight: normal;
        color: #ff0000;
      }
    }
  }

  .fields-list {
    margin: 1rem 0;
    text-transform: uppercase;

    .field {
      margin: 0.5rem 0;

      .photo {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .download-link {
    color: #ff0000;
  }
}

.mm-popup__btn--tool-details-popup-ok {
  background-color: #dddddd;
  cursor: auto;
}

.mm-popup__btn--tool-details-popup-ok.enabled {
  background-color: #ff0000;
  cursor: pointer;
}

.mm-popup__btn--enabled {
  background-color: #ff0000;
  cursor: pointer;
}

@media (min-width: 768px) {

  .tool-details {
    font-size: 1.4rem;

    .web-only {
      display: table-cell;
    }

    span.web-only {
      display: inline;
    }

    .mobile-only {
      display: none;
    }

    .row.tool-event-container {
      margin-left: -8rem;
      margin-right: -8rem;
      background-color: rgba(255, 255, 255, 0.7);
      padding-left: 6.5rem;
      padding-right: 6.5rem;
    }

    .row.tool-event-container table {

      td,
      th {
        font-size: 1.4rem;
        line-height: inherit;
      }

      th {
        padding: 0.5rem 0.2rem 2rem;
      }
    }

    .fields-list {

      .field {

        .photo {
          max-width: 200px;
        }
      }
    }

    /*
		.btn-go {
			width: 15rem;
		} */
  }
}
