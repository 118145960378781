.login .main-container {
  background-image: url('./img/sfondo_mobile.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  bottom: 0;
}

@media (min-width: 768px) {

  .login .main-container {
    background-image: url('./img/sfondo_big.png');
  }
}
