.btn-go {
  width: 100%;
  background-color: red;
  text-transform: uppercase;
  border: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

@media (min-width: 768px) {

  .btn-go {
    font-size: 1.6rem;
  }
}
