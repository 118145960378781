
.profile-body {
  margin: 0;
  padding: 4rem 5rem;
}

.profile-pic-container img {
  width: 100%;
}

.profile-pic-container {
  border-bottom: 1px solid #ffffff;
  padding: 0 0 2rem 0;
  text-align: center;

  img {
    height: auto;
    max-width: 200px;
  }
}

.profile-info-container {
  padding: 2rem 0 0 0;
}

.profile-name {
  font-size: 2rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.profile-secondinfo {
  font-size: 1.5rem;
  line-height: 1.7rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.profile-lastinfo {
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin-bottom: 1.5rem;
}

.profile-name,
.profile-lastinfo,
.profile-role {
  text-transform: uppercase;
}

.profile .main-container {
  background-image: url('./img/sfondo_mobile.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  bottom: 0;
}

.profile .profile-email {
  word-break: break-word;
}

.profile .temp-photo-border {
  border: 3px solid #ffffff;
}

.profile .temp-photo {
  background-image: url(./img/placeholder.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 158px;
  margin: 33px;
  width: auto;
}

.profile .progress {
  overflow: hidden;
  background-color: #f5f5f5;
}

.profile .progress-bar {
  float: left;
  width: 0; /* valore da cambiare in base alla percentuale restituita */
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.profile .progress-bar .progress-bar-value {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.profile .progress-bar-info {
  background-color: #038036;
}

.profile .progress-bar-warning {
  background-color: #efac1b;
}

.profile .progress-bar-alert {
  background-color: #ff0000;
}

@media (min-width: 768px) {

  .profile .main-container {
    background-image: url('./img/sfondo_big.png');
  }

  .profile-body {
    padding: 3rem 3rem;
  }

  .profile-pic-container {
    border-right: 1px solid #ffffff;
    padding: 0 3rem 0 0;
    border-bottom: 0;
  }

  .profile-info-container {
    padding: 0 0 0 3rem;
  }

  .profile-name {
    font-size: 2.2rem;
    line-height: 1.7rem;
  }

  .profile-secondinfo,
  .profile-lastinfo {
    font-size: 1.7rem;
    line-height: 1.9rem;
  }

  .profile .progress {
    max-width: 300px;
  }
}
