li.menu-element {
  display: inline;
  text-transform: uppercase;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

li.menu-element a {
  font-weight: bold;
  color: black;
}

li.menu-element.active span {
  padding-bottom: 1rem;
  border-bottom: 0.5rem solid red;
}
