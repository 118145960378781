@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-Ita.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-MediIta.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: adihaus;
  src: url('../../resources/fonts/AdihausDIN-BoldIta.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-Cond.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-CondIta.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-CondMedium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-CondMediIta.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-CondBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: adihaus-cond;
  src: url('../../resources/fonts/AdihausDIN-CondBoldIta.ttf');
  font-weight: bold;
  font-style: italic;
}
