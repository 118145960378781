.document-details{

   .section-header {
      color: black;
   }

   .main-container{
      bottom: 0;
   }

   textarea{
      width: 100%;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      padding: 1rem 1.5rem;
   }

   .alert {
      color: white;
      background-color: red;
      padding: 10px 20px;
      font-size: 16px;
   }

   .title{
      margin-top: 20px;
      font-size: 2em;
   }
   .block-container{
      background-color: #FFF;
   }
   .block-header{
      margin-top: 30px;
      color: red;
   }
   .web-only {
      display: none;
   }
   .mobile-only {
      display: inherit;
   }
   .department-bullet{
      display: inline-block;
      width: 0.7em;
      height: 0.7em;
      border-radius: 1em;
   }
   .document-item{
      padding: 1em 0;
      border-bottom: 1px solid grey;
      line-height: 1em;
   }
   .highlighted{
      font-weight: bold;
   }
   .field:first-child{
      margin-top: 10px;
   }
   .field span{
      text-transform: uppercase;
   }
   .entry{
      font-weight: bold;
   }



   .row.document-event-container {
       margin-left: -2rem;
       margin-right: -2rem;
       margin-bottom: 50px;
       background-color: rgba(255,255,255,0.8);
       padding: 1.5rem 0.5rem;
   }

   .policy-div {
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 1rem;
      padding-top: 1rem;

      .checkbox-custom-container {
         padding-left: 0;
      }
   }

   .buttons {
      margin-top: 1.5rem;
   }

   .row.document-event-container table  {
      width: 100%;
      font-size: 1.1rem;
      border-bottom: 1px solid #dcdcdc;
      text-transform: uppercase;

      td {
         padding: 1.5rem 0.2rem;
         font-size: 1.1rem;

      }

      th {
         padding: 0rem 0.2rem 2rem 0.2rem ;
         line-height: 1rem;
         color: red;
      }

      tbody td {
         font-weight: bold;

         .due-date {
            font-weight: normal;
            color: red;
         }
      }
   }

   .fields-list {
      margin: 1rem 0;

      text-transform: uppercase;

      .field {
         margin: 0.5rem 0;
         .photo {
             max-width: 100%;
            max-height: 100%;
         }
      }
   }

   .download-link {
      color: red;
   }

   input[type='text']{
      padding: 1rem 1.5rem;
      margin: 1rem 0;
      width: 100%;
      color: black;
   }
}

.mm-popup__btn--document-details-popup-ok {
   background-color: #ddd;
   cursor: auto;
}

.mm-popup__btn--document-details-popup-ok.enabled {
   background-color: red;
   cursor: pointer;
}

.mm-popup__btn--enabled {
    background-color: red;
    cursor: pointer;
}



@media (min-width: 768px) {
   .document-details{

      font-size: 1.4rem;

      .web-only {
         display: table-cell;
      }
      span.web-only {
         display: inline;
      }
      .mobile-only {
         display: none;
      }

      .row.document-event-container {
         margin-left: -8rem;
          margin-right: -8rem;
          background-color: rgba(255, 255, 255, 0.7);
          padding-left: 6.5rem;
          padding-right: 6.5rem;
      }

      .row.document-event-container table {
         td, th {
            font-size: 1.4rem;
            line-height: inherit;
         }

         th {
            padding: 0.5rem 0.2rem 2rem;
         }
      }

      .fields-list {
         .field {
            .photo {
                max-width: 200px;
            }
         }
       }

       .inlinelabel {
             line-height: 64px;
       }
/*
      .btn-go {
         width: 15rem;
      }*/


   }

}





