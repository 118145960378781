.red-row {
  background-color: #ff0000;
  width: 100%;
  min-height: 1rem;
}

.notification-msg {
  position: relative;
  display: block;
  text-align: center;
  height: 5rem;
}

.notification-msg span {
  line-height: 5rem;
  color: #ffffff;
  text-transform: uppercase;
}

.notification-msg .btn-go {
  width: 6rem;
  height: 3rem;
  border: 1px solid #ffffff;
  padding-top: inherit;
  padding-bottom: inherit;
  margin-left: 1rem;
}
