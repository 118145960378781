.left-drawer {
  position: fixed;
  left: -25rem;
  top: 8rem;
  bottom: 0;
  width: 25rem;
  background-color: #000000;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 100;
  transition: left 0.4s linear;

  &.open {
    left: 0;
  }

  .drawer-element {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    line-height: 5rem;
  }
}

.left-drawer .drawer-element div {
  border-bottom: 1px solid #808080;
}

.left-drawer .row:last-child div {
  border: 0;
}

.left-drawer .drawer-element div.active {
  color: #ff0000;
}

span.logged-user {
  font-style: italic;
}

.drawer-logged-user {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {

  .left-drawer {
    display: none;
  }
}
