.tools {

  .section-header {
    color: #000000;
  }

  .title {
    margin-top: 20px;
    font-size: 2em;
  }

  .list-container {
    background-color: #ffffff;
  }

  .list-header {
    margin-top: 30px;
    color: #ff0000;
  }

  .web-only {
    display: none;
  }

  .mobile-only {
    display: inherit;
  }

  .department-bullet {
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-radius: 1em;
  }

  .tool-item {
    padding: 1em 0;
    border-bottom: 1px solid #dcdcdc;
    line-height: 1em;

    .description {
      max-width: 15rem;
    }

    .due-date {
      font-weight: normal;
      color: #ff0000;
    }
  }

  .tool-item:last-child {
    border-bottom: none;
  }

  .highlighted {
    font-weight: bold;
  }

  .button {
    width: 2.5rem;
    padding: 0.2rem 0;
    text-align: center;
    display: block;

    &.arrow {
      font-size: 2rem;
      line-height: 0rem;
      background-image: url('./img/icona_freccia.png');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      height: 1rem;
    }
  }

  .button.highlighted {
    background-color: #ff0000;
    color: #ffffff;
    font-weight: bold;
  }

  .row.tools-list-container {
    margin-left: -2rem;
    margin-right: -2rem;
    background-color: rgba(255, 255, 255, 0.8);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .row.tools-list-container table {
    width: 100%;
    font-size: 1.1rem;
  }

  .row.tools-list-container table {

    td {

      /* padding: 1.5rem 0.2rem; */
      font-size: 1.1rem;
      padding: 1.5rem 0.2rem;
      vertical-align: middle;
    }

    th {
      padding: 1.5rem 0.2rem 2rem 0.2rem;
      line-height: 1rem;
      color: #ff0000;
    }
  }

  .bullet-column {
    width: 1.5rem;
  }
}

@media (min-width: 768px) {

  .tools {

    .web-only {
      display: table-cell;
    }

    span.web-only {
      display: inline;
    }

    .mobile-only {
      display: none;
    }
  }

  .tools .row.tools-list-container {
    margin-left: -8rem;
    margin-right: -8rem;
    background-color: rgba(255, 255, 255, 0.7);
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }

  .tools .row.tools-list-container table {
    width: 100%;
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  .tools .row.tools-list-container table td {
    font-size: 1.4rem;
    vertical-align: middle;
  }

  .tools .button {
    padding: 0.4rem 0;
    text-align: center;
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    width: 4rem;
  }

  .tools .button.arrow {
    font-size: 3rem;
    line-height: inherit;
    display: inline-block;
    padding: 0;
    margin-top: 0.9rem;
    margin-bottom: 0.7rem;
    height: 1.8rem;
    width: 45px;
  }

  .button.highlighted {
    padding: 1rem;
  }

  .row.tools-list-container table {

    td {
      padding: 1rem 0.2rem;
    }
  }
}
