.poptitle {
  width: 100%;
  background-color: #000000;
  text-transform: uppercase;
  height: 4rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 4rem;
  text-align: center;
}

.popbody {
  padding: 2rem;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {

}
