.login-form {
  width: 100%;
  padding: 3rem 8.5rem;
  margin-top: 7rem;
  color: #ffffff;
  text-transform: uppercase;

  .btn-go {
    display: block;
    margin-top: 1rem;
  }
}

.login-form input[type='text'],
.login-form input[type='password'] {
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  color: #000000;
}

.login-form span {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  display: block;
}

.login-form span.forgot-pwd {
  font-size: 1.4rem;
  margin-bottom: 0;
  margin-top: 2rem;
  cursor: pointer;
}

.resetpassword-container .email-field {
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  border: 1px solid #dddddd;
  width: 100%;
  max-width: 270px;
}

@media (min-width: 768px) {

  .login-form {
    width: 40rem;
  }
}
