.uploadForm {

  .btn-go {
    width: 100%;
    background-color: #ff0000;
    text-transform: uppercase;
    border: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: bold;
  }

  input {
    margin: 0 auto;
  }
}

@media (min-width: 768px) {

  .uploadForm {

    .btn-go {
      font-size: 1.6rem;
    }
  }
}
